@import url('nprogress/nprogress.css');
@import url('react-toastify/dist/ReactToastify.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper  {
  max-width: 100% !important;
}
.datePicker .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 10px !important
}
.datePicker .MuiFormControl-root.MuiTextField-root,.autocomplete .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon{
  background: white ;
  border-radius: 3px;
}
.autocomplete .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl{
  padding-top:8px !important;
  padding-bottom:7px !important;
}
.autocomplete 
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-status-fail {
  color: #eb262e;
}

.text-status-pending {
  color: #1890ff;
}

.text-status-success {
  color: #4ae001;
}

.MuiSvgIcon-root.MuiSelect-icon{
  display: inline-block !important;
  font-size: 24px !important;
  right: -8px !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  font-size: 14px !important;
}
input {
  background: white !important;
  border-radius: 3px;
  font-size: 12px !important;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1590px) {
  .wrapper_content {
    display: block !important;
  }
  .wrapper_content_Btn{
    margin: 15px 14px 0 0;
  }
}
